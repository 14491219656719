<script>
import BVForm from '@/components/BVForm/BVForm'

const schema = [
    {
        name: 'NumeroDeDocumento',
        type: 'text',
        label: 'Numero de documento',
        value: null,
        validations: 'required',
        state: ''
    }
]

export default {
    name: 'navegacionAspirante',
    components: {
        BVForm
    },
    data() {
        return {
            formSearch: schema,
            postulaciones: null,
            stepSearch: 1
        }
    },
    methods: {
        onSearch(self) {
            const { NumeroDeDocumento } = self.data;
            console.log(NumeroDeDocumento);

            self.loader = true
            this.$store.getters.fetchGet({ path: `Postulacion/postulaciones/${NumeroDeDocumento}` })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Error al obtener los datos del postulante')
                })
                .then(result => {
                    console.log(result);
                    this.postulaciones = result
                    this.formSearch[0].state = 'success'
                    this.stepSearch = 2
                })
                .catch(() => {
                    this.formSearch[0].state = 'error'
                    this.formSearch[0].errorMessages = ['No se encontró el postulante']
                })
                .finally(() => {
                    self.loader = false
                })
        },
        watcher() {
            this.formSearch[0].state = ''
            this.formSearch[0].errorMessages = []
        }
    },
    computed: {
        styleCard() {
            if (this.stepSearch == 1) {
                return {
                    maxWidth: '500px',
                    width: '90%',
                }
            }
            else {
                return {
                    maxWidth: '100%',
                    width: '100%',
                }
            }
        },
        maincontent() {
            if (this.stepSearch != 1) {
                return {
                    opacity: 1,
                }
            } else {
                return {

                }
            }
        }
    }
}
</script>
<template>
    <div class="container-fluid globalContainer">
        <card class="card-search" :style="styleCard">
            <BVForm :schema="formSearch" :observer="['NumeroDeDocumento']" @watcher="watcher"
                title="Búsqueda de etapa en el proceso de contratación" :callBackSubmit="onSearch">
                <template #text-submit>
                    Buscar
                </template>
            </BVform>
        </card>
        <!-- 54654 -->
        <card class="main-content" :style="maincontent" v-for="postulacion in postulaciones" :key="postulacion.id">
            <b-list-group>
                <b-list-group-item class="d-flex align-items-center">
                    <div class="mr-2">
                        <img :src="postulacion.vacante.cargo.sucursal.empresa.urlLogo"
                            :alt="postulacion.vacante.cargo.sucursal.empresa.nombre" width="70">
                    </div>
                    <div>
                        <h4 class="mt-2">
                            {{ postulacion.vacante.cargo.sucursal.empresa.nombre }}
                        </h4>
                        <p class="mt-1 mb-0">
                            Nombre de la vacante - {{ postulacion.vacante.cargo.nombre }}
                        </p>
                    </div>
                </b-list-group-item>
                <b-list-group-item>
                    <p>
                        Estado de la preselección: {{ postulacion.preseleccionEvaluacion.estado }}
                    </p>
                    <router-link
                        v-if="postulacion.preseleccionEvaluacion.estado == 'Pendiente'"
                        :to="{ name: 'FormularioSubDePostulacion', params: { vacanteId: postulacion.vacante.id, aspiranteId: postulacion.id } }">
                        Editar postulacion
                    </router-link>
                </b-list-group-item>
                <b-list-group-item>
                    <p>
                        Estado de la selección: {{ postulacion.seleccionEvaluacion.estado }}
                    </p>
                    <router-link
                        v-if="
                        postulacion.preseleccionEvaluacion.estado == 'Aprobado' 
                        && postulacion.seleccionEvaluacion.estado == 'Pendiente'"
                        :to="{ name: 'CargarDocumentosDeSeleccion', params: { vacanteId: postulacion.vacante.id, postulacionId: postulacion.id } }">
                        Editar postulacion
                    </router-link>
                </b-list-group-item>
                <b-list-group-item>
                    Estado de la vinculación {{ postulacion.vinculacionEvaluacion.estado }}
                </b-list-group-item>
            </b-list-group>
        </card>
    </div>
</template>

<style scoped>
.globalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card-search {
    min-width: 300px;
    transition: 1s;
    padding: 1rem 2rem;
}

.main-content {
    padding: 1rem 2rem;
    width: 100%;
    opacity: 0;
    transition: 1s;
}
</style>



